/* Downloaded from https://cdn.codebots.com/lampbot/lightning-icons/latest/lightning-icons.css */
/* IMPORTANT: This CSS file must be updated on any bot */

@font-face {
	font-family: 'lightning-icons';
		src:  url('fonts//lightning/lightning-icons1.eot');
		src:  url('fonts/lightning/lightning-icons2.eot') format('embedded-opentype'),
		url('fonts//lightning/lightning-icons.ttf') format('truetype'),
		url('fonts/lightning/lightning-icons.woff') format('woff'),
		url('fonts/lightning/lightning-icons.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
