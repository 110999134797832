/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Search
-------------------------------- */
/*
Found above collection list 
*/

// % protected region % [Change search styles here] on begin
.search {
	display: flex;
	position: relative;
	align-items: center;
	height: auto;

	.input-group {
		@include lightning-icon(before, search);

		margin: 0;
		width: 100%;

		&:before {
			position: absolute;
			padding: 10px 0;
			line-height: 1.4;
			font-size: 16px;
			z-index: 1;
			margin-left: 20px;
		}
	}


	&.small .input-group {
		input[type="text"] {
			padding: 5px 5px 5px 39px;
			font-size: 12px;
		}

		&:before {
			font-size: 12px;
			padding: 6px 0;
			margin-left: 12px;
		}
	}

	input[type="text"] {
		padding: 9px 9px 9px 54px;
		margin: 0;
		line-height: 1.4;
		font-size: 16px;
		box-shadow: none;

		background: $grey-1;
		border: 1px solid $grey-1;

		&:hover {
			border: 1px solid $default-outline;
		}

		&:active,
		&:focus {
			background: transparent;
			border: 1px solid $color-brand-01;

			&::-webkit-input-placeholder,
			&:-moz-placeholder,
			&:-ms-input-placeholder {
				opacity: 0;
				@include fadeOut($animation-time);
			}
		}
	}

	.btn {
		display: none;
		padding: $space-sm;
	}
}
// % protected region % [Change search styles here] end

// % protected region % [Add additional search styles here] on begin
/* search field for imports page */
.search__import-collection {
	/* Fix the height */
	.input-group.input-group-block {
		margin: 0 auto;
	}
	/* Hide the 'Go' button */
	.btn {
		display: none;
	}
	margin-left: auto;
	margin-right: 35px;
}
// % protected region % [Add additional search styles here] end