
.map-toolbar {
  margin: 0;
  z-index: 510;
  background: $grey-10;
  width: 100%;
  height: 50px;

  display: flex;
  flex-direction: row;

  .evaluation-mode-disclaimer-toolbar {
    flex-grow: 2;
    color: $color-support-red;

    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      height: min-content;
    }
  }

  .confirm-button {
    height: 70%;
    border-radius: 5px;
    margin-left: convert-rem(28px);
  }

  ul.left, .right {
    flex-grow: 1;
  }

  .right {
    display: flex;
    justify-content: end;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background: $grey-10;
    display: flex;
    align-items: center;

    &.sublist {
      display: none !important;

      &.expanded {
        display: flex !important;
      }
    }

    li {
      height: 100%;
      color: $white;

      &:hover {
        background: rgba($color-primary, .24);
      }

      &:active, &.active {
        background: $color-primary;

        > .buttons button {
          color: $black;
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      height: 100%;

      button {
        height: 100%;
        background: transparent;
        padding: 10px;
        color: $white;

        &.has-dropdown {
          padding-right: 0;
        }

        &.arrow {
          padding-left: 0;
          padding-right: 10px;
          font-size: 10px;

          &:before {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }

    &.sublist {
      position: absolute;
      display: flex;
      flex-direction: column;

      button {
        width: 130px;
        padding-left: 12px;
        display: flex;
        justify-content: flex-start;
        text-overflow: ellipsis;
      }
    }
  }

  /* Up-down chevron needs extra spacing */
  .zoom-menu {
      padding-left: 10px !important;
  }

  .toolbar-zoom-action-dropdown {
    position: absolute;
    background-color: $grey-10;
    right: 1px; /* width of sidebar + padding */
    width: 250px;
    z-index: 1000;

    font-size: $text-sm;

		.info-fields {
			display: flex;
			align-items: center;
      margin: 5px -7px 0 0;
			p {
        margin-left: 0px;
        margin-bottom: 10px;
				color: $medium-emphasis-text;
			}

			p:first-of-type {
				min-width: 120px;
				width: 120px;
				word-break: break-word;
				white-space: normal;
			}

			.input-group {
				margin: $space-xxxs $space-xxxs $space-xxxs;
				min-width: 170px;
				max-width: 170px;
        padding-left: 5px;
        padding-bottom: 10px;
				input {
					padding: $space-xxs;
          margin: 0;
				}
			}
    }
  }

  .zoom-info {
    display: flex;
    align-items: center;
    padding: 10px 10px 10px 10px;

    cursor: default;

    p:first-of-type {
      margin: 0;
      color: $high-emphasis-text;
      min-width: 70px;
      width: 70px;
      word-break: break-word;
      white-space: normal;
      font-size: $text-sm;
    }
    p:nth-of-type(2) {
      margin: 0;
      color: $medium-emphasis-text;
      margin-left: auto;
      margin-right: 5px;
      font-size: $text-sm;
    }
  }

  .zoom-button {
    width: 70px;
  }

  .clickable {
    cursor: pointer;
    &:hover, &.active {
      background: $color-primary;
      p {
        color: $black;
      }
    }
  }

  .separator {
    border-bottom: 1px solid $default-outline;
    width: 340px;
    margin-right: 10px;
    margin-left: 0;
    position: absolute;
  }

  .generate-fms-button {
    height: 70%;
    border-radius: 5px;

    &:before {
      animation: spin 0.7s linear infinite;
    }
  }
}

/* */
.profile-indicator {
  .profile-indicator-inner {
    display: flex;

    .name-and-role {
      p {
        margin-bottom: 0;
      }
    
      p:first-of-type {
        font-weight: $text-bold;
        color: white;
        font-size: 16px !important;
        line-height: 24px !important;
      }
    
      p:nth-of-type(2) {
        font-weight: $text-light;
        color: rgba($color: #FFFFFF, $alpha: 0.6);
        font-size: 14px !important;
        line-height: 21px !important;
      }
    }
  }
}

.user-image-text {
  height: 35px;
  width: 35px;
  background: $color-support-blue;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  margin-right: 10px;
}

.user-image {
  height: 45px;
  width: 45px;
  min-width: 45px; // Stops the circle from squashing
  max-height: 45px;
  background: $color-support-blue;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 15px;
}
/* */

.toolbars {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.version-status {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.menu-bar {
  margin: 0;
  z-index: 511;
  background: $black;
  width: 100%;
  height: 42px;

  display: flex;
  flex-direction: row;

  .grey-text {
    color: $medium-emphasis-text;
  }

  .evaluation-mode-disclaimer {
    flex-grow: 2;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $color-support-red;
    text-transform: uppercase;

    > div {
      padding: 5px;
      border: 1px solid $color-support-red;
    }
  }

  ul {
    flex-grow: 1;
    list-style-type: none;
    padding: 0;
    margin: 0;
    background: $black;
    display: flex;
    width: max-content;

    .checkable-menu button {
      &:not(.icon-check) {
        padding-left: 30px;
      }

      &.icon-check:before {
        margin-right: 20px;
        width: 0px;
      }

    }

    &.sublist {
      position: absolute;
      flex-direction: column;
      min-width: 216px;
      display: none;

      padding: 5px 0px;

      li {
        position: relative;
        display: flex;

        flex-direction: row;
      }

      .sublist {
        right: -216px;
      }

      &.expanded {
        display: flex;
      }

      button {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: row;

        span.button-content {
          flex-grow: 1;
          text-align: left;
        }

        span.icon-only:before {
          margin: 0 0 0 10px;
        }
      }

      div.about {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $white;
        font-size: 14px;        
        font-weight: 400;
        line-height: 1;
        cursor: default;
        @include appearance-none;
	    @include fontSmooth;

        .mat-version {
          color: $medium-emphasis-text;
          font-size: 12px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  li {
    height: 100%;
    color: $white;
    cursor: pointer;

    &.separator {
      border-bottom: 1px solid $disabled-outline;
    }

    /* Set specific styles for the help search field */
    &.help-search {
      cursor: default;

      .input-group {
        margin: 10px;

        input {
          padding: 8px $space-xs 8px $space-lg;
        }

      }

      &:hover {
        background: $black;
      }
    }

    &:hover, &.active {
      background: $color-primary;

      > button,
      > .buttons button,
      > .buttons button .grey-text,
      > button .grey-text {
        color: $black;
      }
    }
  }

  .help-menu li:last-child {
    &:hover, &.active {
      background: $black;
    }
  }

  button {
    background: transparent;
    color: $white;
    transition: none !important;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    height: 100%;

    button {
      height: 100%;
      background: transparent;
      padding: 10px;
      color: $white;

      font-size: 14px;

      &:after {
        margin-left: $space-xxs !important;
      }
    }
  }
}

.version-status {
  display: flex;
  flex-grow: 1;

  align-items: center;
  font-size: 14px;

  .save-indicator-icon {
    margin-right: $space-xxs;
    font-size: x-large;

    &.last-saved {
      color: #75D979;
    }

    &.offline {
      color: #FF3A3A;
    }

    &.saving {
      animation: rotation 3s infinite linear;
    }
  }
  
  .last-saved-label {
    color: $medium-emphasis-text;
    margin-bottom: 0;
    font-size: unset;
    display: flex;
    justify-content: end;
    flex-grow: 1;
    width: 150px;

    &.offline {
      color: #FF3A3A;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .version-status-label {
    font-size: 12px;
    font-family: $body-font-family;
    margin-left: $space-xxs;
    display: flex;
    justify-content: center;
    align-items: center;

    &.Original {
      background: #EEEEEE;
      color: #464646;
      padding: $space-xxxs $space-lg;
      margin-right: $space-xs;
    }

    &.Unpublished {
      background: $color-primary;
      color: #464646;
      padding: $space-xxxs $space-xxs;
    }

    &.Published {
      background: rgba(255, 171, 138, 0.12);
      color: $color-primary;
      padding: $space-xxxs $space-lg;
      margin-right: $space-xs;
    }
  }
}

.map-object-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: $space-xs;

  .map-object-indicator {
    font-size: 12px;
    padding: $space-xxxs;
    background: $white;
    color: #464646;
    margin-left: $space-us;

    &:last-of-type {
      margin-right: 0;
    }

    &.unpublished {
      background: $color-primary;
    }

    &.published {
      background: rgba(255, 171, 138, 0.12);
      color: $color-primary;
    }
  }
}

// context menu right click styling
.separator-contextmenu {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.clickable-custom-menu {
  cursor: pointer;
  &:hover, &.active {
    background: $color-primary;
    color: $black;
    width: 254px;
  }
}

.custom-context-menu {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  background-color: #383838;
  border-radius:  1px solid rgba(255, 255, 255, 0.4);
  padding: 5px 2px 5px 2px;
  width: 252px;
  line-height: 21px;
  height: auto;
  margin: 0;
  list-style: none;
  color: rgba(255, 255, 255, 0.87);
  padding-left: 8.24%;
  z-index: 11000;
}

.custom-context-menu-disabled {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  background-color: #383838;;
  border-radius:  1px solid rgba(255, 255, 255, 0.4);
  padding: 5px 2px 5px 2px;
  width: 252px;
  line-height: 21px;
  height: auto;
  margin: 0;
  list-style: none;
  color: rgba(255, 255, 255, 0.38);
  padding-left: 8.24%;
  pointer-events: none;
  z-index: 11000;
}

.context-menu-parent{
  background-color: #383838;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding-top: 4px;
  padding-bottom: 4px;
  width: 255px;
  line-height: 21px;
  height: auto;
  margin: 0;
  list-style: none;
  color: rgba(255, 255, 255, 0.38);
}

// mouse cursor location styling
.mouse-cursor-location-parent {
  background-color: $background;
  padding: 4px 6px 4px 8px;
  width: 207px;
  display: flex;
  flex-direction: column;
  color: $white;
  z-index: 500;
  position: absolute;
  right: 283px;
  bottom: 18px;

  &.no-side-panel {
    right: 58px;
  }

  .coords {
    display: flex;
    flex-direction: row;

    &.icon-left {
      &:before {
        margin-right: 5px;
      }
    }

    .coords-east {
      margin-right: 5px;
    }
  }

  .elevation.icon-left {
    &:before {
      margin-right: 5px;
    }
  }
}