.user-permissions-modal-subheading {
	color: rgba(255, 255, 255, 0.6);
	margin-bottom: 4rem;
}

.team-page {
	.collection-component {
	  padding: 0;
	}
  
	.align-right {
	  	justify-content: end;
		height: 5.75rem;
	}

	#new_team__member_button {
		margin: $space-md 0 $space-xs 0;
	}

	.info-combobox {

		.input-group {
			width: inherit;
			min-width: inherit;
			margin: 0;
		}

		.input-group__dropdown div.dropdown {
			margin: 0 !important;
			width: inherit;
			min-width: inherit;
			height: inherit;
			min-height: inherit;
			padding: $space-xxs;

			&:not(.upward) .menu {
				border-top: 0 !important;
			}

			&.upward .menu {
				border-bottom: 0 !important;
			}

			i {
				top: unset;
				right: 0px;
				margin: 0;
				padding: 0;
			}

			&.disabled {
				.icon {
					display: none;
				}
			}
		}

	} 

  }