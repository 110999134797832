.leaflet-container {
  height: 100%;
  width: 100%;

  background: #000000;

  image-rendering: pixelated;
  will-change: transform;

  > img {
    background: black;
  }

  * {
    font-family: $body-font-family !important;
  }

  .input-group__checkbox label {
    font-size: $text-xs;
  }
}

.leaflet-tooltip {
  padding: 0px;
  background-color: transparent;
  border: none;
  color: white;
  white-space: nowrap;
  box-shadow: none;
  font-size: $text-xs;
  font-weight: $text-regular;
  font-family: $body-font-family;
}

.leaflet-tooltip.icon-circle-fill::before {
  margin-right: calc(0.5 * 1rem);
}

.leaflet-tooltip.icon-circle-fill.error::before {
  color: $color-support-red;
}

.leaflet-tooltip.icon-circle-fill.warning::before {
  color: $color-support-yellow;
}

.leaflet-image-layer {
  cursor: default;
}

.leaflet-pixi-overlay {
  position: absolute;
  z-index: 99;
}

.center {
  .spinner-container {
    height: 100vh;
    width: 100vw;
  }
}

.object-sidebar {
  position: absolute;
  width: 265px;
  height: calc(100% - 92px);
  background-color: $background;
  top: 92px;
  z-index: $z-index-over-leaflet;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;

  .search .input-group {
    min-width: auto;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
  }

  .side-panel-content {
    //margin-top:
  }

  /* CSS Tree menu styles */
  div.tree {
    padding: 15px 0 40px;
    font-family: $body-font-family;
    font-weight: $text-regular;

    .bold-label {
      font-weight: $text-semibold;
    }

    .no-results {
      padding: 0 17px;
    }

    .map-object {
      display: flex;
      align-items: center;
      padding: 3px 17px;
      margin: 2px 0;

      &:hover {
        background: rgba($color-primary, .25);
      }

      &.item-active {
        background: $color-primary;

        .label {
          color: $black !important;

          &::selection {
            color: $black !important;
          }
        }

        .expand-button:before, .show-hide-button:before {
          color: $black;
        }
      }

      * {
        font-size: $text-xs;
      }

      .label {
        cursor: pointer;
        color: $high-emphasis-text;
        margin-left: 10px;
        flex-grow: 1;
        display: flex;
        overflow-wrap: anywhere;

        user-select: all;

        &::selection {
          background: transparent;
          color: $high-emphasis-text;
        }

        &.error-map-object-color {
          color: $color-support-red;
        }

        &.warning-map-object-color {
          color: $color-support-yellow;
        }

        &.new-map-object-color {
          color: $color-primary;

          &::selection {
            color: $color-primary;
          }
        }

        &.icon-left:before {
          margin: 0;
          margin-right: 5px;

          // Set the default colour
          color: #BEBEBE;
        }

        &.icon-left {
          margin-left: 5px;
        }

        &.bay-loading:before {
          color: #FFFFFF;
        }

        &.bay-parking:before {
          color: #A52A2A;
        }

        &.bay-fuelling:before {
          color: #FFFF00;
        }

        &.bay-paddock-dumping:before {
          color: #FF8000;
        }

        &.bay-overedge-dumping:before {
          color: #FF0000;
        }

        &.bay-crusher-dumping:before {
          color: #00FF00;
        }

        &.icon-AOZ:before {
          color: #FFFF00;
        }

        &.icon-Exclusion:before {
          color: #FFFFFF;
        }

        &.icon-Barrier:before {
          color: #D3D3D3;
        }

        &.icon-Lockout:before {
          color: #FF0000;
        }

        &.icon-Obstacle:before {
          color: #FFFF00;
        }

        &.icon-Autonomous:before {
          color: #0000FF;
        }

        &.park-location:before {
          color: rgba(#800000, .8);
        }

        &.dig-location:before {
          color: rgba(#804040, .8);
        }

        &.waste-dump-location:before {
          color: rgba(#00C462, .8);
        }

        &.crusher-location:before {
          color: rgba(#0000FF, .8);
        }

        &.stockpile-location:before {
          color: rgba(#0000A0, .8);
        }

        &.route-beacons:before {
          color: #FFF;
        }

        &.route-beacons-added:before {
          color: #800080;
        }

        &.route-beacons-deleted:before {
          color: #804000;
        }

        &.access-beacons:before {
          color: #FFFF00;
        }

        &.access-beacons-added:before {
          color: #800080;
        }

        &.access-beacons-deleted:before {
          color: #804000;
        }

        &.segments:before {
          color: #704F41;
        }
        &.segments-added:before {
          color: #41704A;
        }
        &.segments-deleted:before {
          color: #464170;
        }

        &.icon-area-new:before {
          vertical-align: middle !important;
        }
      }

      .expand-button:before, .show-hide-button:before {
        margin: 0;
        cursor: pointer;
      }

      .expand-button:before {
        font-size: 10px;
      }

      .show-hide-button.hidden, .expand-button.hidden {
        visibility: hidden;
      }

      .show-hide-button, .expand-button {
        visibility: visible;
      }

      .nesting {
        display: inline-block;
        width: 15px;
        min-width: 15px;
      }
    }
  }
  
  .map-check-errors {
    display: flex;
    justify-content: space-around;
    padding: 10px 17px;
    border-top: convert-rem(1px) solid $default-outline;
    background-color: $background;
    position: fixed;
    width: 265px;
    height: 40px;
    bottom: 0;
    
    .label {
      color: $medium-emphasis-text;
      display: flex;

      &.icon-left:before {
        margin-right: 8px;
      }
    }
  }
  
  .search-bar {
    padding: 0 17px;
  }
  
  .section-divider {
    margin-top: 15px;
    border-bottom: convert-rem(1px) solid $default-outline;
    width: 100%;
  }
    // HIT-322
  .dot-error {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 15px;
    top: 8px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);

    &.error {
      background: $color-support-red;
    }

    &.warning {
      background: $color-support-yellow;
    }
  }

  .error-layers-panel {
    color: rgba(255, 255, 255, 0.6) !important;
    font-style: normal;
    font-size: 12px;
    padding-left: 22px;
    margin-top: 7px;
    margin-left: 12px;

    &.error {
      color: $color-support-red !important;
    }

    &.warning {
      color: $color-support-yellow !important;
    }
  }
}

.hide.object-sidebar {
  width: 40px;
}

.error-button-styling {
  position: absolute;
  width: 126px;
  height: 30px;
  left: 4px;
  top: 6px;
  background-color: rgb(56, 56, 56, 0.2);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.warning-button-styling {
  position: absolute;
  width: 126px;
  height: 30px;
  left: 135px;
  top: 6px;
  background-color: rgb(56, 56, 56, 0.2);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.button-styling-active {
  background: #383838;
  font-weight: $text-semibold;
}

.error-red {
  color: $color-support-red;
}

.warning-yellow {
  color: $color-support-yellow;
}