.project-building-overlay {

  opacity: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $black;
  cursor: pointer;
  left: 0;
  top: 0;
  z-index: 11;

  .overlay-content-wrap {
    position: relative;
    top: 60%;
    left: 10%;
    display: inline-block;
    max-width: 80%;

    .building-text {
      font-size: 50px;
      color: $white;

      @include breakpoint(sm) {
        font-size: 40px
      }
    }

    .progress {
      width:40vw;
      border-radius:30px;
      background-color: $grey-0;
      overflow: hidden;
      max-width: 100%;

      .progress-bar {
        height: 60px;
        background-image: linear-gradient(to right, $color-primary 0%,$color-brand-01 100%);
        border-radius: 30px;
        transition: 0.1s ease width;
      }
    }
  }
}
