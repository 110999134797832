/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Sidebar
-------------------------------- */
/*
Sidebar that displays information sliding from either left or right side.
Example is a history/audit button.
*/

// % protected region % [Change sidebar styles here] on begin
//Button to open the sidebar
.sidebar__btn {
	margin: 0 0 0 $space-lg;
}

.sidebar {
	position: absolute;
	width: 265px;
	height: calc(100% - 92px);
	background-color: $background;
	top: 92px;
	right: 0;
	z-index: $z-index-over-leaflet;

	.sidebar-heading {
		padding: 22px 20px 5px;
	}

	h6 {
		margin: 0;
	}

	.input-group__checkbox {
		margin: 0;

		&.extra-margin {
			margin-top: 10px;
		}
	}

	&-info {
		background-color: $background;
		top: 0;
		right: 0;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		width: auto;
		display: flex;
		flex-direction: column;
		z-index: $z-index-over-leaflet;
		@include slideInRight($animation-time);
		padding: 10px 0 100px 16px;

		p {
			margin: 0;
			font-size: 12px;
		}
		
		h6 {
			margin-top: 15px;
		}
		
		.section-divider {
			margin-top: 15px;
			border-bottom: convert-rem(1px) solid $default-outline;
			width: 340px;
			margin-left: -20px;
		}

		.info-section {
			.properties-input:first-of-type {
				font-weight: 500;
			}
		}

		.section-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: $space-xs 0;

			h6 {
				margin: 0;
			}

			.close-button {
				padding: 0;

				&:after {
					margin-top: 0;
				}
			}
		}

		.header-border {
			border-bottom: convert-rem(2px) solid $white;
			width: 340px;
			margin: 0 0 10px -20px;
		}

		.sidebar-popout {
			.additional-properties-input {
				position: absolute;
				background-color: $background;
				padding: 0 20px 10px;
				right: 265px; /* width of sidebar + padding */
				width: 340px;
				z-index: $z-index-over-leaflet;

				.section-header {
					margin: 6px 0;
				}

				.section-divider {
					margin-top: 0;
					margin-bottom: 12px;
				}
			}
		}

		.properties-input {
			display: flex;
			align-items: center;
			//margin: 5px 0px;
			margin-top: 10px;
			min-height: 28px; // Only set min, so they can grow when there is a lot of label text
			width: 226px;

			p {
				color: $medium-emphasis-text;
			}
			
			.label {
				width: 74px;
				padding-right: 11px;

				p:first-of-type {
					white-space: normal;
				}
			}
			
			.inline-error {
				display: flex;
				flex-direction: column;
			}

			&.custom {
				.label {
					padding-left: 12px !important;
				}

				.info-fields {
					margin-left: 37px !important;
					height: 28px !important;
				}
			}

			.info-fields {
				width: 145px;
				display: inline-flex;
				justify-content: space-between;
				align-items: center;
				height: inherit;
				min-height: 28px; // fixes issue with height of empty combobox
				
				.input-group {
					margin: 0;
					width: inherit;
					min-width: inherit;
					height: inherit;
					min-height: inherit;

					input {
						margin: 0;
						height: inherit;

						&.unit-0 {
							width: 145px;
						}

						&.unit-1 {
							width: 130px;
						}

						&.unit-2 {
							width: 115px;
						}

						&.unit-3 {
							width: 100px;
						}

						&.unit-4 {
							width: 85px;
						}
					}
				}

				/* Not great using importants, but need to override the defaults for this particular case */
				.input-group__dropdown div.dropdown {
					margin: 0 !important;
					width: inherit;
					min-width: inherit;
					height: inherit;
					min-height: inherit;
					padding: $space-xxs;

					&:not(.upward) .menu {
						border-top: 0 !important;
					}

					&.upward .menu {
						border-bottom: 0 !important;
					}

					i {
						top: unset;
						right: 0px;
						margin: 0;
						padding: 0;
					}

					&.disabled {
						.icon {
							display: none;
						}
					}
				}
			}

			.switch-wrapper {
				width: 46px;
				height: 21px;
				min-width: convert-rem(46px);
				min-height: 21px;

				.switch {
					position: relative;
					display: inline-block;
					width: 46px;
					height: 21px;
					min-width: convert-rem(46px);
					min-height: 21px;
	
					input {
						position: absolute;
						display: none;
					}
	
					.slider {
						border-radius: 13px;
						position: absolute;
						cursor: pointer;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background-color: #9A9A9A;
						-webkit-transition: 0.4s;
						transition: 0.4s;
					}

					.slider.inactive {
						cursor: not-allowed;
					}
					
					.slider:before {
						content: "";
						height: 17px;
						width: 17px;
						border-radius: 50%;
						position: absolute;
						left: 2px;
						bottom: 2px;
						background-color: $grey-8;
						-webkit-transition: 0.4s;
						transition: 0.4s;
					}
					
					input:checked + .slider {
						background-color: $color-primary;
					}
					
					input:checked + .slider:before {
						-webkit-transform: translateX(25px);
						-ms-transform: translateX(25px);
						transform: translateX(25px);
					}
				}
			}
		}

		.input-column {
			flex-direction: column;
			align-items: flex-start;
			
			.input-group {
				width: inherit;
				min-width: inherit;
				margin: 0;
				
				textarea {
					height: 134px;
					margin: 5px 0 0 ;
					padding: 5px 12px;
				}
			}

			textarea {
				resize: none;
				width: 100%;
			}
		}
		
		.sub-heading {
			margin-top: 10px;
			p {
				color: $white;
			}
		}

		h2 {
			padding: $space-sm;
			border-bottom: convert-rem(2px) solid $grey-2;
		}

		ul {
			padding: $space-md;
			margin: 0;
		}

		>ul,
		>ol {
			list-style: none;

			>li {
				position: relative;
				padding-left: $space-lg;
			}
		}

		//Give a before class for a timeline look for li elements
		&.sidebar-info__history {
			>ul {
				>li {
					p {
						margin: 0;
						padding: $space-sm 0;
						display: flex;

						.bold {
							font-weight: $text-bold;
							margin-right: $space-sm;
						}

						&:after {
							margin-left: auto;
							color: $grey-2;
						}
					}

					&:hover,
					&:active,
					&:focus,
					&.active {
						p:after {
							color: get-contrasting-text-color($background);
							@include transform-timing($animation-time);
						}
					}

					//rotate on expansion of list
					&.active {
						p:after {
							@include rotate($animation-time, 180deg);
							animation-fill-mode: forwards;
						}
					}

					&:before {
						content: '';
						background-color: $grey-2;
						border-radius: 50%;
						height: $space-sm;
						width: $space-sm;
						display: block;
						position: absolute;
						left: 0;
						top: convert-rem(20px);
					}

					&:after {
						content: '';
						border-left: convert-rem(2px) solid $grey-2;
						position: absolute;
						left: convert-rem(7px);
						height: 100%;
						top: convert-rem(21px);
						margin-left: 0;
					}

					&:last-of-type {
						&:after {
							display: none;
						}
					}

					//Sub audits - depth information
					ul {
						list-style: none;
						opacity: 1;
						height: 100%;
						padding: 0;
						@include cubic-bezier-ease-in($animation-time);

						li {
							position: relative;
							padding: .2rem $space-lg;

							&:before {
								content: '';
								background-color: $grey-2;
								border-radius: 50%;
								height: $space-xs;
								width: $space-xs;
								display: block;
								position: absolute;
								left: 0;
								top: convert-rem(13px);
							}

							&:after {
								content: '';
								border-left: convert-rem(2px) solid $grey-2;
								position: absolute;
								left: convert-rem(3px);
								height: 100%;
								top: convert-rem(21px);
							}

							&:last-of-type {
								&:after {
									display: none;
								}
							}
						}

						&.hidden {
							opacity: 0;
							height: 0;
							max-height: 0;
							@include cubic-bezier-ease-in($animation-time);
						}
					}
				}
			}
		}
	}
	
	.connectivity-table, .turn-signal-table {
		margin-top: 15px;
		display: flex;
		justify-content: space-between;
		width: 206px;
		
		.connectivity-icon, .turn-signal-icon {
			width: 28px;
		}
		.properties-column {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			max-width: 92px;
			
			.label {
				width: inherit;
				height: 38px;
				padding: 0;
			}

			.disabled-info-fields {
				width: inherit;
				align-items: center;
				height: 28px;
				margin-bottom: 10px;
				margin-left: 5px;
				color: rgba(255, 255, 255, 0.38);
			}

			.button-width {
				width: 14px ;
			}

			.numberTextField-error {
				background: rgba(255, 58, 58, 0.5);
				margin: 0;
				width: 60px;
			}

			.info-fields {
				width: 70px;
				align-items: center;
				height: 28px;
				margin-bottom: 10px;
				justify-content: space-between;
				display: flex;
				
				NumberTextField {
					margin: 0;
				}

				span {
					width: 28px;	
					padding-left: 5px;
				}

				.input-group {
					margin: 0;
					min-width: inherit;
					height: inherit;
					min-height: inherit;

					input {
						padding: $space-xxs;
						margin: 0;
						height: inherit;
						width: 60px;
					}
				}
			}
		}
	}

	.turn-signal-table {
		width: 220px;
		.properties-column:last-child {
			width: 15px;
		}
		.properties-column {
			.info-fields {
				width: 50px;
				.input-group {
					input {
						width: 40px;
						margin-right: 2px;
					}
				}
			}
		}
	}
}
// % protected region % [Change sidebar styles here] end

// % protected region % [Add additional sidebar styles here] on begin
.errors-and-warnings {
	margin-top: $space-xxms;

	.errors-and-warnings-item {
		display: flex;
		align-items: flex-start;
		padding-right: 12px;

		.error {
			min-width: 11px;
			height: 11px;
			border-radius: 50%;
			background-color: $color-support-red;
			margin-right: $space-xxs;
			margin-top: $space-xxxs;
		}

		.warning {
			min-width: 11px;
			height: 11px;
			border-radius: 50%;
			background-color: $color-support-yellow;
			margin-right: $space-xxs;
			margin-top: $space-xxxs;
		}
	}
}


.hide.sidebar {
	width: 40px;
}

.side-panel-content {
	margin-top: 35px;
}

.sidebar, .object-sidebar {
	> .btn, > .sidebar-button .btn  {
		z-index: $z-index-over-leaflet;
		position: absolute;
		display: flex;
		padding: 12px 8px;
		width: 40px;
		&:after {
			margin-top: 0;
		}

		&:not(.show-hide-button) {
			right: 0px;
		}
	}

	#map-sidebar {
		padding-bottom: 50px;
	}

	.sidebar-property-header{
		display: flex;
		justify-content: space-between;
		align-items: center;

		.property-button {
			display: flex;
			flex-direction: row-reverse;

			> .btn{
				margin-top: 15px;
				width: 14px;
				height: 14px;
				&:after {
					margin-left: 0;
				}
			}
		}		
	}
}

.connectivity-submit, .turn-signal-submit {
	background-color: $color-primary;
	border-radius: 4px;
	float: right;
	width: 67px;
	height: 28px;
	margin-top: 10px;
}

.connectivity-sidebar-popout, .turn-signal-sidebar-popout {
	.additional-properties-input {
		position: absolute;
		background-color: $background;
		padding: 0 20px 10px;
		right: 265px; /* width of sidebar + padding */
		width: 265px;
		z-index: $z-index-over-leaflet;

		.section-header {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			margin: 6px 0;
			width: 219px;
		}

		.section-divider {
			margin-top: 0;
			margin-bottom: 12px;
			width: 265px;
		}
	}
	.additional-properties-input-error {
		position: absolute;
		background-color: $background;
		padding: 0 20px 10px;
		right: 265px; /* width of sidebar + padding */
		width: 265px;
		z-index: $z-index-over-leaflet;

		.section-header {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			margin: 6px 0;
			width: 219px;
		}

		.section-divider {
			margin-top: 0;
			margin-bottom: 12px;
			width: 265px;
		}

		.input {
			background: rgba(255, 58, 58, 0.5);
		}
	}
}

.inline-validation {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	float: right;
	align-items: flex-start;
	position: relative;


	.inline-button {
		border-radius: 2px;
		border: 0.5px solid rgba(255, 255, 255, 0.4);
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);

		&.icon-only {
			&:before { 
				margin: -5px;
				width: 16px;
				height: 16px;
			}
		}

		&.btn {
			position: absolute;
			right: 30px;
			top: 20px;
			z-index: 1000;
			padding: 8px;
		}

		&.cross {
			&.btn {
				right: 0px;
			}
		}

		&.unit-tick {
			&.btn {
				right: 45px;
			}
		}

		&.unit-cross {
			&.btn {
				right: 15px;
			}
		}

		&.unit-long-tick {
			&.btn {
				right: 88px;
			}
		}

		&.unit-long-cross {
			&.btn {
				right: 58px;
			}
		}
	}
}

.validation-error {
	input {
		background-color: rgba(255, 58, 58, 0.5);
	}
}

.inline-error-box {
	right: 260px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 8px 16px;
	gap: 8px;

	position: absolute;
	width: max-content;
	height: 34px;
	background: #DDDDDD;
	border: 0.5px solid rgba(255, 255, 255, 0.4);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 2px;
	color: black;

	.inline-error-message {
		height: 18px;

		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		text-align: right;
		color: #000000;
		flex: none;
		order: 0;
		flex-grow: 0;
	}

}

.add-connectivity-link-id, .add-turn-signal{
	display: flex;
	flex-direction: row !important ;
	justify-content: space-between;
    margin-bottom: 0;
    margin-top: 10px;
	height: 30px;

	label {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		color: rgba(255, 255, 255, 0.6);
		padding-top: 5px;
	}

	input {
		width: 145px;
		margin: 0px;
		right: 30px;
	}
}

.add-connectivity-link-id-error, .add-turn-signal-error{
	display: flex;
	flex-direction: row !important ;
	justify-content: space-between;
    margin-bottom: 0;
    margin-top: 10px;
	height: 30px;

	label {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		color: rgba(255, 255, 255, 0.6);
		padding-top: 5px;
	}

	input {
		width: 145px;
		margin: 0px;
		right: 30px;
		background-color: rgba(255, 58, 58, 0.5);
	}
}

.add-turn-signal, .add-turn-signal-error {
	align-items: center;
	label {
		width: 60px;
	}
	input {
		width: 120px;
		right: 55px;
	}
}
// styling for the shift bay popout 
.shift-bay-popout {
	.additional-properties-input {
		position: absolute;
		background-color: $background;
		padding: 0 10px 10px;
		right: 265px; /* width of sidebar + padding */
		width: 265px;
		z-index: $z-index-over-leaflet;
		filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

		.section-header {
			font-family: 'Poppins';
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			color: #FFFFFF;
			margin: 4px;
		}
	}
}

.section-divider-shift-bay {
	margin-top: 0;
	margin-bottom: 12px;
	width: 265px;
	border: 1px solid rgba(255, 255, 255, 0.6);
	margin-left: -10px;
}

.shift-bay-styling{
	display: flex;
	flex-direction: row !important ;
	justify-content: space-between;
    margin-bottom: 0;
    margin-top: 10px;
	margin-left: 14px;
	height: 28px;
	box-sizing: border-box;

	label {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: rgba(255, 255, 255, 0.6);
		padding-top: 4px ;
	}

	input {
		width: 50%;
		margin: 0px;
		right: 30px;
		margin-right: 20px;
	}

	&.count {
		input {
			width: 147px;
			margin-right: -10;
			margin-right: -2px;
		}
	}
}

.shift-bay-submit {
	background-color: $color-primary;
	border-radius: 4px;
	float: right;
	width: 67px;
	height: 28px;
	margin-top: 20px;
	margin-bottom: 10px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 13px !important ;
	line-height: 20px;
}

.flex {
	display: inline-flex;
}

.unit-shift-bay, .unit-add-turn-signal {
	margin-left: -45px;
    margin-top: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.6);

	&.multi-bay {
		margin-left: -42px !important;
	}
}

.distance-field {
	display: flex;
	flex-direction: row !important ;
	justify-content: space-between;
    margin-bottom: 0;
    margin-top: 10px;
	margin-left: 0px;
	height: 28px;
	box-sizing: border-box;
	

	label {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: rgba(255, 255, 255, 0.6);
		padding-top: 4px ;
	}

	input {
		width: 50%;
		margin: 0px;
		right: 30px;
		margin-right: 20px;
		width: 126px;
    	border: 0.5px solid rgba(255, 255, 255, 0.6);
	}	

	&.count {
		width: 145px !important;
		margin-right: 0px !important;
	}
}

.count-field {
	display: flex;
	flex-direction: row !important ;
	justify-content: space-between;
    margin-bottom: 0;
    margin-top: 10px;
	margin-left: 0px;
	height: 28px;
	box-sizing: border-box;
	

	label {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: rgba(255, 255, 255, 0.6);
		padding-top: 4px ;
	}

	input {
		margin: 0px;
		right: 30px;
		width: 125px;
    	border: 0.5px solid rgba(255, 255, 255, 0.6);
		width: 145px !important;
		margin-right: 0px !important;
	}	
}

.input-wrapper-styling {
	flex-direction: row;
	padding-left: 14px;

	label {
		margin-top: 15px;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 20px;
		color: #FFFFFF;
		margin-left: -10px;
	}

	input {
		margin-top: 15px;
		z-index: 1000;
	}
}


.publishedVersionBox {
	width: 286px;
	height: 110px;
    background: #333333;
	border: 1px solid #363636;
	margin-top: 10px;
	margin-bottom: 14px;

	&:active {
		background: #333333;
		border: 1px solid #FF5A00;
	}

	&:hover {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), #333333;
	}
}

.version {
	margin-left: 18px;
    margin-top: 13px;
	height: 21px;
	width: 21px;
	background: #737373;
	border-radius: 50%;	
	
	&.tripleDigit {
		width: 250px;
		border-radius: 60%;
	}

	h5 {
		position: relative;
		width: 14px;
		height: 16px;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		display: flex;
		align-items: center;
		color: #FFFFFF;
		margin: 3px;
		padding: 1px 1px 1px 1px;

		&.doubleDigit {
			position: relative;
			width: 25px;
			height: 16px;
			font-family: "Poppins";
			font-style: normal;
			font-weight: 500;
			font-size: 10px;
			line-height: 18px;
			display: flex;
			align-items: center;
			color: #FFFFFF;
			margin: 2px;
			overflow: hidden;
		}

		&.tripleDigit {
			padding:  0px;
			overflow: visible;
			font-size: 10px;
		}
	}

}
.container {
	display: flex;
	flex-direction: row;
	height: 40px;
}

.date-published {
	margin-top: 15px;
    margin-left: 8px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	height: 100%;
	white-space: normal;
    overflow: inherit;
    display: block;
	display: flex;
	align-items: center;
	color: #FFFFFF;

	&.tripleDigit {
		margin-left: 2px;
	}
}

.published-contains {
	margin-left: 20px;
    margin-top: 19px;
    color: rgba(255, 255, 255, 0.87);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    color: rgba(255, 255, 255, 0.87);
}

.download-icon-styling {
	margin-left: 10px !important;
}

// % protected region % [Add additional sidebar styles here] end