/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

/***** Admin top bar switch between front-end and admin end *****/
$admin-top-bar-height: 2.4rem;

.admin__top-bar {
	background-color: rgb(34, 34, 34);
	display: flex;
	justify-content: flex-end;
	top: 0;
	grid-area: adminbar;
	height: $admin-top-bar-height;

	ul {
		margin: 0.5rem 0;

		li {
			list-style: none;

			.link-rm-txt-dec {
				color: rgb(255, 255, 255);
				margin-right: 2rem;
				font-family: 'Poppins',
					sans-serif;
				text-decoration: none;
				&:after {
					content: "\e92d";
					display: inline-block;
					font-family: 'lightning-icons';
					color: rgb(255, 255, 255);
					margin-left: 1rem;
				}
				&:hover, &:active {
					text-decoration: underline;
				}
			}
		}
	}
}