/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Colours
-------------------------------- */
/*
_colors.scss is the base on setting up your colour variables in your target project
Set your target colours but do not remove variables that you do not plan to use.
*/

/* Main colours */
/* -------------------------------------------------*/
/* ----------Set your brand colours here ----------*/
/* -------------------------------------------------*/

// % protected region % [Set colours, accent colours, and greyscale colours] on begin
//Primary and theme colour
$color-primary: #FFAB8A; // Peach
$color-secondary: #F1F1F1; // Grey
$color-brand-01: #FF5A00; // Hitachi Orange
$color-brand-02: #656565; // Dark Grey
$color-secondary-steelgrey: #A3A3A3;

$high-emphasis-text: rgba(255, 255, 255, 0.87); // High emphasis text
$medium-emphasis-text: rgba(255, 255, 255, 0.6); // Medium emphasis
$disabled-text: rgba(255, 255, 255, 0.38); // Disabled

$default-outline: rgba(255, 255, 255, 0.4);
$disabled-outline: rgba(255, 255, 255, 0.12);

// Accent Colours
$color-support-red: #FF3A3A;
$color-support-green: rgba(117, 217, 121, 1) !default; // #75D979 // Support color green
$color-support-blue: #3EAEFF; // #009FDD // Support color blue
$color-support-yellow: #EED202;
$color-support-orange: rgba(251, 164, 65, 1) !default; // #F27600 // Support color orange
$color-support-purple: rgba(91, 15, 211, 1) !default; // #8623DF // Support color purple

//Greyscale
$black: #121212; // 00dp
$grey-10: #1E1E1E; // 01dp
$grey-8: #232323; // 02dp
$grey-6: #252525; // 03dp
$grey-5: #272727; // 04dp
$grey-4: #2C2C2C; // 06dp
$grey-3: #2E2E2E; // 08dp
$grey-2: #333333; // 12dp
$grey-1: #363636; // 16dp
$grey-0: #383838; // 24dp
$white: rgb(255, 255, 255); // #ffffff

//Assigning theme colours
$background: $grey-8;
$hover-state:  $color-primary;
$element-hover:  $color-primary;

//Text color - do not use. Use for function
$color-text-dark: $high-emphasis-text;
$color-text-light: $black;
// % protected region % [Set colours, accent colours, and greyscale colours] end

/*
From here are calculations that are made to create shades and shadow
colours using the colours that have been set above.

It is not recommended to make these changes unless you specifically want
an assigned shade to the variables.
*/
// % protected region % [Set colour shades] off begin
/* Colour Variables */
$color-shades: 10%;
$color-alpha: 0.8%;
$color-shadow: 0.2%;

/* Colour Variations */
$color-primary-light: lighten($color-primary, $color-shades);
$color-primary-dark: darken($color-primary, $color-shades);
$color-primary-bg: rgba($color-primary, $color-alpha);

$color-secondary-light: lighten($color-secondary, $color-shades);
$color-secondary-dark: darken($color-secondary, $color-shades);
$color-secondary-bg: rgba($color-secondary, $color-alpha);

$color-brand-01-light: lighten($color-brand-01, $color-shades);
$color-brand-01-dark: darken($color-brand-01, $color-shades);
$color-brand-01-bg: rgba($color-brand-01, $color-alpha);

$color-brand-02-light: lighten($color-brand-02, $color-shades);
$color-brand-02-dark: darken($color-brand-02, $color-shades);
$color-brand-02-bg: rgba($color-brand-02, $color-alpha);

/* Support colors */
$color-support-red-light: lighten($color-support-red, $color-shades);
$color-support-red-dark: darken($color-support-red, $color-shades);
$color-support-red-bg: rgba($color-support-red, $color-alpha);

$color-support-green-light: lighten($color-support-green, $color-shades);
$color-support-green-dark: darken($color-support-green, $color-shades);
$color-support-green-bg: rgba($color-support-green, $color-alpha);

$color-support-blue-light: lighten($color-support-blue, $color-shades);
$color-support-blue-dark: darken($color-support-blue, $color-shades);
$color-support-blue-bg: rgba($color-support-blue, $color-alpha);

$color-support-yellow-light: lighten($color-support-yellow, $color-shades);
$color-support-yellow-dark: darken($color-support-yellow, $color-shades);
$color-support-yellow-bg: rgba($color-support-yellow, $color-alpha);

$color-support-orange-light: lighten($color-support-orange, $color-shades);
$color-support-orange-dark: darken($color-support-orange, $color-shades);
$color-support-orange-bg: rgba($color-support-orange, $color-alpha);

$color-support-purple-light: lighten($color-support-purple, $color-shades);
$color-support-purple-dark: darken($color-support-purple, $color-shades);
$color-support-purple-bg: rgba($color-support-purple, $color-alpha);
// % protected region % [Set colour shades] end
$color-list: (primary : $color-primary, secondary : $color-secondary, brand-01 : $color-brand-01, brand-02: $color-brand-02, standard-blue : $color-support-blue, standard-yellow : $color-support-yellow, standard-red : $color-support-red, standard-green : $color-support-green, standard-orange : $color-support-orange, grey-0 : $grey-0, grey-1 : $grey-1, grey-2 : $grey-2, grey-4 : $grey-4, grey-6 : $grey-6, grey-8 : $grey-8, grey-10 : $grey-10, );
// % protected region % [Add additional colours here] off begin
// % protected region % [Add additional colours here] end