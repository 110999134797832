/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

.alert {
	&.alert__info {
	// % protected region % [Change alert info styles here] off begin
		.Toastify__progress-bar{
			background-color: rgba($color-support-blue,0.7);
		}

		.Toastify__close-button{
			color: $color-primary;
			align-self: center;
		}
	// % protected region % [Change alert info styles here] end
	// % protected region % [Add additional alert__info styling] off begin
	// % protected region % [Add additional alert__info styling] end
	}

	// % protected region % [Add additional alert here] off begin
	// % protected region % [Add additional alert here] end
}
// % protected region % [Add additional style that does not fall under alert class] off begin
// % protected region % [Add additional style that does not fall under alert class] end
