/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Cards
-------------------------------- */
/*
Cards are often used to display a group of component inside a 'box'
*/

// % protected region % [Change cardsstyles here] on begin
/* Cards with multiple background colors */
.cards-wrapper {
	display: flex;
	margin: $space-sm auto;

	//wrapper for horizontal cards
	&.horizontal {
		flex-direction: row;

		.card {
			margin: 0 $space-xs;
			align-items: flex-start;
		}
	}

	//the card itself
	.card {
		padding: $space-md;
		min-height: convert-rem(120px);
		min-width: convert-rem(250px);
		display: flex;
		flex-direction: column;
		cursor: pointer;

		&--rounded {
			border-radius: .5rem;
		}

		.h4 {
			margin: 0;
		}

		// each loop goes through color list and assigns bg colour and contrast colour
		@each $color-class,
		$color in $color-list {
			&--#{$color-class} {
				background-color: $color;
				color: get-contrasting-text-color($color);
			}
		}
	}
}
// % protected region % [Change cardsstyles here] end

// % protected region % [Add additional cards styles here] on begin
.info-card {
	background: red;
	background: $background;
	padding: 15px;
	display: flex;
	flex-direction: column;
	width: 350px;

	.img {
		background: $black;
		height: 150px;
	}

	.row {
		display: flex;
		flex-direction: row;
		margin-top: 10px;

		.title {
			font-weight: $text-bold;
			flex-grow: 1;
			font-size: 16px;
		}
	}

	.content {
		margin-top: 10px;
	}
}
// % protected region % [Add additional cards styles here] end