/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Login
-------------------------------- */
/*
Login, register and reset password pages
*/

// % protected region % [Change login, register and reset password styles here] on begin
.login,
.reset-password,
.register,
.activation {
	display: flex;
	align-items: flex-start;
	align-content: center;
	flex-direction: column;
	width: 70vw;
	max-width: 700px;
	margin: 0 auto;
	height: 100vh;
	justify-content: center;
	overflow-y: hidden;


	@include media(xs, sm, md) {
		height: auto;
		margin: $space-sm auto;
	}

	h1 {
		margin: 0 0 $space-xxs 0;
		@include media(xs, sm, md) {
			margin: 0 0 $space-md 0;
		}

		&.login-mat-text-bottom {
			color: $color-primary;
		}
		&.activation-mat-text-bottom,
		&.reset-password-mat-text-bottom {
			color: $color-primary;
		}
	}

	h3 {
		color: $medium-emphasis-text;
		padding-bottom: $space-sm;
	}

	.input-group {
		width: 100%;
		max-width: unset;
		margin: .5rem 0;
		display: flex;

		label {
			font-weight: $text-regular;
		}

		.icon-look {
			position: absolute;
			right: 0;
			top: 2.6rem;

			&:hover {
				text-decoration: none;
				color: $color-secondary;
				@include transform-timing($animation-time);
			}
			&:before {
				margin-right: 0;
			}
		}
	}

	.activation-confirm-password {
		display: flex;
	}

	.activation-ul-left{
		font-family: 'Poppins';
		font-style: italic;
		font-weight: 400;
		font-size: 14px;
		margin-top: 0;
	}

	.activation-ul-right{
		padding-left: 100px;
		font-family: 'Poppins';
		font-style: italic;
		font-weight: 400;
		font-size: 14px;
		margin-top: 0;
	}

	.activation-ul-left-error{
		color: #FF3A3A;
		font-family: 'Poppins';
		font-style: italic;
		font-weight: 400;
		font-size: 14px;
		margin-top: 0;
	}

	.activation-ul-right-error{
		color: #FF3A3A;
		padding-left: 100px;
		font-family: 'Poppins';
		font-style: italic;
		font-weight: 400;
		font-size: 14px;
		margin-top: 0;
	}

	.btn.activation-submit {
		display: flex;
		align-self:flex-end;
		background: #FFAB8A;
	}

	.btn.reset-password-submit {
		display: flex;
		align-self:flex-end;
		background: #FFAB8A;
		margin-left: 24px;
	}

	.reset-password-buttons {
		display: flex;
		justify-content: end;
		float: right;
		width: 100%;
		align-items: center;
		margin-top: $space-md;
	}

	.btn-group {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		margin-top: $space-md;

		@include media(xs, sm, md) {
			margin-top: $space-xs;
		}

		@media screen and (max-width: 620px) {
			flex-direction: column;
		}

		div {
			flex-grow: 1;
		}

		.btn {
			display: flex;
			margin: 0;

			@include media(xs, sm, md) {
				padding: $space-xs;
				margin: $space-xs auto;
			}

			@media screen and (max-width: 620px) {
				width: 100%;
				box-sizing: border-box;
			}

			&.btn--secondary {
				margin-right: $space-lg;

				@media screen and (max-width: 620px) {
					margin-right: 0;
				}
			}
		}

		.btn.login-submit {
			flex-grow: 0.12;
			&::after {
				margin-left: auto;
			}
		}
	}

	.login-buttons > .link-forgotten-password {
		text-decoration: underline;
		font-weight: $text-light;
	}
}

.register {
	align-items: flex-start;

	a {
		text-decoration: none;
		cursor: pointer;
		min-height: convert-rem(20px);
		margin-bottom: $space-lg;
	}

	h5 {
		margin-top: 0;
	}

	.input-group {
		width: 100%;
	}
}

.login__forgot-password {
	display: flex;

	&:hover,
	&:active {
		border-bottom: none;
	}
}

.login-image {
	width: 100%;
	height: 100%;
}

.column .hitachi-logo {
	position: absolute;
	top: 50px;
	right: 50px;

	width: 180px;
}

.password-input-icon {
	position: inherit;
	top: 28%;
	right: 1%;
	&:hover {
		opacity: 40%;
	}
	color: #F1F1F1;
}

.password-container {
	display: flex;
	input {
		width: 100%;
	}
	span {
		margin-top: 1.4rem;
		margin-left: -3rem;
		z-index: 0;
		color: #F1F1F1;
	}
}
// % protected region % [Change login, register and reset password styles here] end

// % protected region % [Add additional login, register and reset password styles here] off begin
// % protected region % [Add additional login, register and reset password styles here] end