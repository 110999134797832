.settings-page {
  .settings-component {
    width: 40vw;
    margin: 65px auto 0;
    font-family: $body-font-family;

    .settings-logo {
      border-bottom: convert-rem(4px) solid $color-brand-01;
      text-align: center;
      margin-bottom: 68px;
      display: flex;
      justify-content: center;

      img {
        width: 145px;
        margin-bottom: -20px;
      }

      .logo-placeholder {
        background: $color-secondary-steelgrey;
        width: 145px;
        height: 145px;
        margin-bottom: -20px;
      }
    }
    
    .title {
      margin-bottom: 20px;
    }
    
    .btn-group {
      justify-content: flex-end;
    }
  }
}