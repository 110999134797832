@charset "UTF-8";

@font-face {
  font-family: "mat";
  src:url("./fonts/mat.eot");
  src:url("./fonts/mat.eot?#iefix") format("embedded-opentype"),
    url("./fonts/mat.woff") format("woff"),
    url("./fonts/mat.ttf") format("truetype"),
    url("./fonts/mat.svg#mat") format("svg");

  font-weight: normal;
  font-style: normal;
}
