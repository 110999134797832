/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Modal
-------------------------------- */
/*
This is the container for modals, which places them above the other content and applies a light overlay to the content
*/

// % protected region % [Change modal styles here] on begin
.modal-container {
	background-color: rgba($background, 0.75);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
}

/*The container for the modal content proper*/
.modal-content {
	position: relative;
	top: 30%;
	border: 1px solid $default-outline;
	background: $background;
	color: get-contrasting-text-color($background);
	overflow: auto;
	border-radius: $btn-radius;
	outline: none;
	padding: $space-md;
	height: fit-content;
	max-height: convert-rem(500px);
	overflow: auto;
	width: 50%;
	margin: 0 auto;
	z-index: $z-index-modal;

	@include breakpoint(sm) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: convert-rem(500px);
		width: 90%;
		margin: auto;
		left: 0;
		right: 0;
		bottom: 0;
	}

}

.modal {

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.btn {
			padding: 0;
		}
		
		.cancel-button {
			color: #FFFFFF;
		}
	}

	.input-group__textarea {

		textarea {
			width: 100%;
			max-width: unset;
		}
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		margin-top: $space-md;

		.btn:not(:last-child) {
			margin-right: $space-md;
		}
	}
}
// % protected region % [Change modal styles here] end

// % protected region % [Add additional modal styles here] on begin
.mat-modal-field {
	margin-bottom: $space-xxs;
	
	.input-group {
		margin: 0;
		min-width: auto;
	}
	.grey-font {
		color: $medium-emphasis-text;
	  }
}

.map-file-upload-modal, .mat-modal {
	top: 10%;
	max-height: 80vh;
	padding: 45px 40px;

	&.user-permissions-modal {
		width: 34%;
	}

	h4 {
		margin: 0 0 $space-md 0;
	}

	.modal__header {
		margin-bottom: 34px;
		align-items: center;

		h3 {
			margin: 0;
		}

		button:after {
			font-size: 18px;
		}

		&.user-permissions-modal {
			display: flex;
			align-items: flex-start;
			
			border-bottom: 1px solid rgba(255, 255, 255, 0.4);
			padding-bottom: 1.5rem;
			margin-bottom: 1.5rem;
		}
	}

	.mat-modal-bottom-border {
		border-bottom: 1px solid rgba(255, 255, 255, 0.4);
		margin-bottom: calc(1.5 * 1rem) !important;
		padding-bottom: 25px;
	}

	.map-name-input, .mat-modal-input {
		label {
			margin-bottom: $space-xxs;
		}

		.input-group input {
			margin: 0;
		}

		.help-text {
			margin: 0;
			padding: 0;
			color: $medium-emphasis-text;
			font-style: italic;
			font-weight: $text-light;
		}
	}

	.mat-modal-input {
		.input-group__dropdown .dropdown__container.single {
			margin-top: 8px;
		}
	}

	.modal-actions {
		display: flex;
		justify-content: flex-end;

		.btn {
			margin-bottom: 0;
		}

		.btn:first-of-type {
			min-width: 130px;
			margin-right: $space-sm;
		}

		.next-button {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.upload {
		button {
			font-size: 16px;
			padding: 13px 18px;
		}
	}
}

.invite-modal-actions {
	margin-right: -15px;
	.btn:first-of-type {
		min-width: 130px;
		margin-left: auto;
	}
}

.map-file-upload-modal-overlay .mat--modal-overlay {
	background: rgba(18, 18, 18, .8);
}


.map-publish-and-export-modal {
	top: 25%;
	max-height: 80vh;
	padding: 45px 40px;
	width: 451px;
	overflow-y: hidden;

	&.area-edit-modal {
		height: convert-rem(456px);
	}

	.modal__header {
		margin-bottom: 14px;
		align-items: center;

		h3 {
			margin: 0;
		}

		button:after {
			font-size: 18px;
		}

	}

	.map-name-input, .mat-modal-input {
		label {
			margin-bottom: $space-xxs;
		}

		.input-group input {
			margin: 0;
		}

		.help-text {
			margin: 0;
			padding: 0;
			color: $medium-emphasis-text;
			font-style: italic;
			font-weight: $text-light;
		}
	}

	.mat-modal-input {
		.input-group__dropdown .dropdown__container.single {
			margin-top: 8px;
		}
	}

	.modal-actions {
		display: flex;
		justify-content: flex-end;

		.btn {
			margin-bottom: 0;
		}

		.btn:first-of-type {
			min-width: 130px;
			margin-right: $space-sm;
		}

		.next-button {
			margin-left: 0;
			margin-right: 0;
		}
		
		&.export-btn-group {
			.btn:first-of-type {
				margin-top: $space-xs;
			}

			.next-button {
				margin-top: $space-xs;
			}
		}
	}

}
.file-export-section {
	h4 {
		padding: 0;
		margin: 0;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		display: flex;
		align-items: center;
		color: rgba(255, 255, 255, 0.6);
	}
	.section-divider {
		margin-top: 15px;
		border-bottom: convert-rem(1px) solid $default-outline;
		width: 363px;
		margin-left: 0px;
		border: 1px solid rgba(255, 255, 255, 0.12);
	}

	.checkbox-styling {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 150px;
		padding-top: 25px;
	}
	
	.export-area-text{
		display: flex;
		align-items: center;
		margin-top: $space-lg;
		
		span {
			color: $color-brand-01;
			margin-left: -0.5rem;
			margin-top: -0.5rem;
			align-self: flex-start;
		}
		
		p {
			font-weight: 400;
			font-size: 12px;
			padding-left: $space-xxs;
		}
	}
}

.modal-note {
	display: flex;
	align-items: center;
	margin-top: $space-lg;

	span {
		color: $color-brand-01;
		margin-left: -0.5rem;
		margin-top: -0.5rem;
		align-self: flex-start;
	}

	p {
		font-weight: 400;
		font-size: $text-sm;
		padding-left: $space-xxs;
	}
}

.map-parameters-modal {
	top: 15%;
	max-height: 70vh;
	padding: 45px 40px;
	overflow-y: hidden;

	.map-parameters-modal-content {
		height: 505px;

		.input-group__dropdown {
			font-family: $body-font-family;
		}
		
		.parameters-list-container {
			font-family: $body-font-family;
			overflow-y: auto;
			height: 80%;
			
			.parameters {
				padding: 0 15px;

				.collection__list {
					overflow-x: auto;

					.list__header{
						background: $background;
						font-weight: 600;
						font-size: 16px;
						line-height: 16px;

						td:nth-child(2) {
							width: 250px;
						}
					}

					.collection__item {
						background: $background;
						font-weight: 400;
						font-size: 14px;
						line-height: 21px;
						border: none;
						border-top: 1px solid rgba(255, 255, 255, 0.4);

						td:nth-child(2) {
							width: 350px;
							white-space: pre-wrap;
						}
					}
					
					th, td {
						background: $background;
						padding-top: 11px;
						padding-bottom: 11px;
					}
					
					.superscript-unit {
						vertical-align:super;
					}
				}
			}
		}
	}

}
// % protected region % [Add additional modal styles here] end