
.help-page {
  .help-links {
    margin-top: 65px;

    .column:not(:last-of-type) {
        margin-right: 93px;
    }

    h5 {
      text-transform: uppercase;
      border-bottom: 1px solid $color-brand-01;
      margin-bottom: 14px;
    }

    ul {
      list-style-type: none;
      padding: 0;
      min-width: 200px;

      a {
        font-size: $text-lg;
        border-bottom: 0;
        display: block;
        border-bottom: convert-rem(1px) solid transparent;
        height: 30px;

        &:hover {
          color: $white;
          text-decoration: underline;
        }

        &:after {
          float: right;
          color: $medium-emphasis-text;
          font-size: 12px;

          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      div.about {
        font-family: $body-font-family;
        font-size: $text-lg;
        height: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .mat-version {
          color: $medium-emphasis-text;
          font-size: 14px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

    }

  }
}