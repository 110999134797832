.all-imports-page {
  
  display: flex;
  flex-direction: row;

  padding-left: 56px;
  height: fit-content;
  min-height: 100%;

  .spinner-container {
    margin: auto;
  }

  .main-content {
    padding-top: 56px;
    flex-grow: 1;

    .collection__pagination {
      padding: 0;
    }

    .collection__list {
      overflow-x: initial;
      overflow-y: initial;
    }

  }

  .imports-title-section {
    display: flex;
    flex-direction: row;

    .map-object-indicators {
      margin-right: 0;
    }

    .version-status-label {
      font-size: 12px;
      font-family: $body-font-family;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;

      &.Original {
        background: #EEEEEE;
        color: #464646;
        padding: $space-xxxs $space-lg;
      }

      &.Unpublished {
        background: $color-primary;
        color: #464646;
        padding: $space-xxxs $space-xxs;
      }

      &.Published {
        background: rgba(255, 171, 138, 0.12);
        color: $color-primary;
        padding: $space-xxxs $space-lg;
      }
    }

    .back-button {
      background-color: #FFAB8A1F;
      color: #F4A484;
      margin-right: 2rem;
      align-self: center;
      padding: 9px 5px;

      height: 34px;
      width: 34px;

      &:before {
        margin-bottom: 0;
      }

      &:hover {
        background: $color-primary;
        color: $color-text-light;
      }
    }

    .name {
      font-size: $text-xxl;
      margin-bottom: 0;
      font-weight: $text-bold;
      flex-grow: 1;
    }

    .search {
      margin: 0;

      input {
        width: 300px;
      }
    }

    .latest-import-owner {
      p {
        margin-bottom: 0;
        font-size: $text-sm;
      }
    }
  }

  .import-name-alignment {
    align-items: center;
  }

  .grey-font {
    color: $medium-emphasis-text;
  }

  .main-imports-view, .main-content > .imports-title-section {
    margin-right: 56px;
  }

  .latest-import-card {
    min-height: 190px;
    width: 100%;
    background-color: #2E2E2E;
    cursor: pointer;
    padding: 23px 26px;
    display: flex;
    flex-direction: column;

    .last-modified-section {
      margin: 0;
      margin-bottom: 10px;
    }

    .latest-import-name {
      margin: 0;
    }

    .latest-import-card-bottom {
      margin-top: 1rem;
    }

    &.active {
      padding: calc(23px - 1px) calc(26px  - 1px);
    }

    .no-import-margin {
      align-self: center;
      margin-top: 5rem;
    }

    .view-map-button {
      border-radius: 5px;
      width: 16%;
    }

    .spinner-with-warning {
      text-align: center;

      .spinner {
        margin-left: 27rem;

        .spinner-container {
          height: 10rem;
        }
      }
    }
  }

  .imports-title {
    margin-top: 3rem;
    margin-bottom: 0;
  }

  .latest-import-title-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 22px;
    margin-bottom: 16px;

    .new-import-button {
      margin-right: 0;
      align-self: center;
      height: fit-content;
      width: fit-content;
    }

    .imports-title {
      margin: 0;
      display: flex;
      align-items: center;
    }
  }

  .imports-collection {
    // max-width: 65rem;
    padding: 0;

    table {
      font-size: smaller;
      white-space: normal;

      td {
        text-align: left;
        white-space: normal;
      }

      thead {
        font-size: .78rem;
      }
    }
  }

  .imports-footnote {
    text-align: center;
    width: 45%;
    color: grey;
    margin: auto auto 20px;
  }

  .clickable-row, .latest-import-card {
    border: 1px solid black !important;
  }

  .clickable-row.active, .latest-import-card.active {
    border: 2px solid #E64C00 !important;
  }

  .import-page-side-panel {
    padding-top: 52px;
    padding-left: 46px;
    width: 375px;
    background: #1E1E1E;

    border-left: $default-outline solid .5px;
    position: relative;

    display: flex;
    flex-direction: column;
    max-height: 96vh;

    .side-panel-main-frame {
      height: 90vh;
      overflow-y: auto;
    }

    .arrow {
      position: absolute;
      margin-left: -92px;
      
      &:before {
        content: '';
        display: block;
        position: absolute;
        //right: 100%;
        top: 50%;
        margin-top: -10px;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-right: 25px solid $default-outline;
        border-bottom: 20px solid transparent;
        border-left: 21px solid transparent;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        //right: 100%;
        top: 50%;
        margin-top: -10px;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-right: 25px solid #1E1E1E;
        border-bottom: 20px solid transparent;
        border-left: 22px solid transparent;
      }
    }

    .side-panel-content-margin {
      .side-panel-content-section {
        margin-top: 2rem;
        margin-bottom: 0;
      }

      .side-panel-no-published {
        margin-left: 15px;
      }
    }

    .sidebar-title {
      margin-top: 65px;
    }

    .last-modified-section {
      margin-bottom: 10px !important;
    }
  }

  .latest-version-status {
    background-color: #473D39;
    color: #F4A484;
    height: fit-content;
    width: fit-content;
    padding: 9px 21px;
    align-self: end;

    p {
      margin-bottom: 0;
    }
  }

  .side-panel-import-name {
    margin-top: 1rem;
  }

  .icon-download {
    padding-left: 0;
    padding-right: 0;
    display: inline-block;

    @extend .txt-sm-body;

    span {
      text-decoration: underline;
    }

    &:hover {
      border: 0;
    }
  }
}

.archive-btn-div {
  background-color: #1E1E1E;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;

  .archive-button {
    background-color: #F1F1F11F;
    color: #F1F1F1;
  }
}

.all-maps-page {
  .collection-component {
    padding: 0;
  }

  .align-right {
    justify-content: end;
  }
}

.spinner-div{
  margin-left: 40%;
}

.modal-btn-group {
  justify-content: end;
  margin-top: 2rem;
}

.archive-modal {
  width: 40rem;
}

.modal-button {
  width: 7rem;
}

.modal-question {
  font-size: large;
  font-weight: 500;
}

.map-edit-btn-group {
  width: 0 !important;

  .btn:hover {
    color: $color-primary !important;
  }
}

.collection__menu {
  margin: 0 !important;
}

//HIT-40
.empty-state-section {
  .empty-state-background {
    box-sizing: border-box;
    position: absolute;
    width: 536px;
    height: 410px;
    left: 331px;
    top: 212px;
    background: rgba(255, 171, 138, 0.12);
    border: 1px solid #FFAB8A;
  }
  
  h1 {
    position: absolute;
    width: 394px;
    height: 114px;
    left: 383px;
    top: 263px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 57px;
    color: #FFFFFF;
  }
  
  h2 {
    position: absolute;
    width: 291px;
    height: 84px;
    left: 383px;
    top: 421px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    color: rgba(255, 255, 255, 0.87);
  
    }

  .empty-state-new-button {
    position: absolute;
    width: 241px;
    height: 241px;
    left: 725px;
    top: 443px;
    background: linear-gradient(149.36deg, #FFAB8A 7.42%, #FF5A00 150.34%);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: #000000;
  }
}
